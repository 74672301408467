.body {
  width: 100%;
  min-height: 100vh;
  background-image: url("./media/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.tamilFont {
  font-family: 'Mukta Malar', sans-serif;
}

ul {
  list-style: none;
}

ul li:before {
  content: '•';
  margin-right: 2%;
  color: green;
  font-weight: bold;
}
li {
  margin-top: 10px;
}

#rowOfAmountAndPeriod {
  display: grid;
}

#package {
  padding-top: 5%;
}

#PackageName {
  font-size: 25px;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: bold;
}

#Amount {
  padding-top: 2%;
  padding-bottom: 2%;
  font-weight: bold;
  font-size: 16px;
  color: green;
}

#packageYear {
  font-weight: bold;
  color: blue;
}

#paymentButton {
  border-radius: 40%;
  width: 100px;
}

#buttonDesign {
  
}
