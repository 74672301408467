#padding
{
  padding: 2%;
}
#paddings
{
  padding: 2%;
}
#image
{
  height: 376px;
}
#size
{
  width: 50px;
  height: 50px;
}
#error_msg
{
  color: red;
  padding: 2%;
  font-size: 20px;
  font-style: italic;
  font-weight: bolder;
}
